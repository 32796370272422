import i18n from 'i18next';
import locize from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(locize)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.NODE_ENV === 'development',
    saveMissing: true,
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
    backend: {
      saveMissing: true,
      projectId: '1155a050-1d0e-4304-a50c-15b603653f47',
      apiKey: '1d75ada5-8a02-45e3-92a0-e9408fc51f8a',
      referenceLng: 'en-GB',
    },
    nsSeparator: false,
    keySeparator: false,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.warn(lng, ns, key, fallbackValue);
    },
  });

export default i18n;
